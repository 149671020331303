import { useState } from 'react'
import { useRouter } from 'next/router'
import Link from 'next/link'
import Svg from 'components/svg/svg'
import Icon from 'components/icon/icon'
import Taxonomies from 'components/taxonomies/taxonomies'
import Autocomplete from 'components/autocomplete/autocomplete'
import HeaderIcons from 'components/header-icons/header-icons'
import Promo from 'components/promo/promo'
import useAppState from 'utils/state'
import { cn } from 'utils/helpers'
import { getIsMinimal } from 'utils/helpers'
import styles from './header.module.scss'
import HeaderDelivery from 'components/header-delivery/header-delivery'

export default function Header() {
    const [showTaxonomies, setShowTaxonomies] = useState(0)
    const { coupon } = useAppState()
    const { pathname } = useRouter()
    const minimal = getIsMinimal(pathname)
    const hidePromo = ['/pradekite-prekyba', '/prisijungimas', '/registracija', '/slaptazodzio-atkurimas'].includes(
        pathname
    )
    const alt = false //pathname == getRoute('landing') || pathname == '/'

    return (
        <>
            {!hidePromo && coupon && <Promo coupon={coupon} pathname={pathname} />}
            <div
                className={cn(
                    styles.headerContainer,
                    alt ? styles.alt : styles.primary,
                    minimal ? styles.minimal : styles.full
                )}
            >
                <div className={styles.bg} />
                <div className={styles.header}>
                    <div className={styles.wrap}>
                        <div className={styles.top}>
                            {!minimal && (
                                <Icon
                                    label="Kategorijos"
                                    icon="menu"
                                    onClick={() => setShowTaxonomies(1)}
                                    className={cn(styles.icon, styles.menu, 'desktopHide')}
                                />
                            )}
                            <div className={styles.left}>
                                <Link href="/">
                                    <a aria-label="Lietuviška prekė" className={styles.logo}>
                                        <Svg name="logo" />
                                    </a>
                                </Link>
                            </div>
                            {minimal || (
                                <div className={styles.middle}>
                                    <Autocomplete />
                                    <HeaderDelivery />
                                </div>
                            )}
                            <HeaderIcons alt={alt} />
                        </div>
                        {!minimal && (
                            <Taxonomies
                                alt={alt}
                                mobileShow={showTaxonomies}
                                hideTaxonomies={() => setShowTaxonomies(0)}
                            />
                        )}
                    </div>
                    <Svg className={styles.paper} name="paper-header" />
                </div>
            </div>
        </>
    )
}

//const filterBtn = !(pathname == getRoute('suggestions') || pathname == '/')
//import HeaderFilterCta from 'components/header-filter-cta/header-filter-cta'
// {filterBtn && <HeaderFilterCta />}
