import { useEffect } from 'react'
import styles from './backdrop.module.scss'
import { cn } from 'utils/helpers'

export default function Block({ onClick, className }) {
    useEffect(() => {
        if (className) {
            document.querySelector('html').classList.add('noScroll')
        } else {
            document.querySelector('html').classList.remove('noScroll')
        }
    }, [className])

    return <div className={cn(styles.backdrop, className)} onClick={onClick} />
}
