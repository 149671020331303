import Svg from 'components/svg/svg'
import Icon from 'components/icon/icon'
import styles from './cart.module.scss'
import headerIconsStyles from 'components/header-icons/header-icons.module.scss'
import useAppState from 'utils/state'
import { cn, getRoute } from 'utils/helpers'
import { trackInitiateCheckout } from 'utils/analytics/events'

export default function Cart({ pathname }) {
    const { cart } = useAppState()
    const cartUrl = getRoute('checkout-cart')
    const notEmpty = !!cart.items.length
    const isCheckout = pathname.startsWith(getRoute('[checkout]'))

    const handleClick = () => {
        trackInitiateCheckout(cart.items)
    }

    return (
        <div className={styles.cart}>
            <Icon
                href={cartUrl}
                onClick={handleClick}
                label="Krepšelis"
                icon={notEmpty ? 'basket-2' : 'basket'}
                className={cn(headerIconsStyles.icon, isCheckout && headerIconsStyles.iconActive)}
            >
                {notEmpty && <Svg name="dot" className={styles.dot} />}
            </Icon>
        </div>
    )
}
