import Link from 'next/link'
import Image from 'components/image/image'
import Price from 'components/price/price'
import { trackAutocompleteClicked } from 'utils/analytics/events'
import styles from './product.module.scss'
import { getRoute } from 'utils/helpers'

export default function Product({ props, item, onClick }) {
    const handleClick = e => {
        trackAutocompleteClicked(item)
        onClick(item, e.target.tagName === 'A')
    }

    return (
        <div {...props} className={styles.product} onClick={handleClick}>
            <div className={styles.image}>
                <Image src={item.image.replace('www.','api.')} alt={item.name} layout="responsive" width={72} height={72} />
            </div>
            <div className={styles.right}>
                <div className={styles.text}>
                    <h3>{item.name}</h3>
                    <Link href={getRoute('brand', item)}>
                        <a itemProp="brand" className={styles.brand}>
                            {item.brand_name}
                        </a>
                    </Link>
                </div>
                <Price product={item} className={styles.price} withOld={false} />
            </div>
        </div>
    )
}
//<div className={styles.price}>{item.price_preview}</div>
