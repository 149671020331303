import { useState, useEffect } from 'react'
import Link from 'next/link'
import Icon from 'components/icon/icon'
import Submenu from './submenu'
import Backdrop from 'components/backdrop/backdrop'
import { cn, getRoute } from 'utils/helpers'
import groups from 'utils/data/groups'
import styles from './taxonomies.module.scss'
import useAppState from 'utils/state'

export default function Taxonomies({ mobileShow, hideTaxonomies, alt }) {
    const [active, setActive] = useState(null)
    const [useGroups, setUseGroups] = useState(groups)
    const [activeMobile, setActiveMobile] = useState(null)
    const { features } = useAppState()
    const [activeMobileList, setActiveMobileList] = useState(null)

    useEffect(() => {
        setUseGroups(
            groups.map(group => {
                if (group.url === '/isskirtinumai') {
                    group.children = features
                }
                return group
            })
        )
    }, [features])

    const hideMenuHandler = () => {
        setActiveMobile(null)
        setActiveMobileList(null)
        hideTaxonomies(false)
        setActive(null)
    }

    return (
        <>
            <Backdrop className={mobileShow && styles.backdropShow} onClick={hideMenuHandler} />
            <div
                className={cn(styles.taxonomies, mobileShow && styles.mobileShow, alt && styles.alt)}
                onMouseLeave={() => setActive(null)}
            >
                <Icon className={cn(styles.icon, 'desktopHide')} icon="x" onClick={hideMenuHandler} />
                <div className={cn(styles.listName, 'desktopHide')}>Meniu</div>
                {useGroups.map((group, a) => (
                    <div key={a} onMouseEnter={() => setActive(a)}>
                        <Submenu
                            group={group}
                            index={a}
                            alt={alt}
                            hideMenu={hideMenuHandler}
                            active={active === a}
                            activeMobile={activeMobile === a}
                            activeMobileList={activeMobileList}
                            setActiveMobile={setActiveMobile}
                            setActiveMobileList={setActiveMobileList}
                        />
                    </div>
                ))}
                <div onMouseEnter={() => setActive(null)}>
                    <Link href={getRoute('taxonomy', { path: 'specialus-pasiulymai' })}>
                        <a onClick={hideMenuHandler}>
                            <span>Akcijos</span>
                        </a>
                    </Link>
                </div>
                <div onMouseEnter={() => setActive(null)}>
                    <Link href={getRoute('shops')}>
                        <a onClick={hideMenuHandler}>Parduotuvės</a>
                    </Link>
                </div>
            </div>
        </>
    )
}
