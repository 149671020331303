import dynamic from 'next/dynamic'
import { useEffect } from 'react'
import styles from './header-delivery.module.scss'
import Svg from 'components/svg/svg'
import useAppState from 'utils/state'
import { get } from 'utils/api'
import Cookies from 'universal-cookie'
import { useModal } from 'components/modals/provider'

const Delivery = dynamic(() => import('components/modals/delivery/delivery'))

const names = {
    lt: 'Lietuvoje',
    eu: 'Europos Sąjungoje',
    global: 'Visame pasaulyje'
}

export default function HeaderDelivery() {
    const { showModal } = useModal()
    const { delivery } = useAppState()
    const cookies = new Cookies()
    const cookieDelivery = cookies.get('delivery')

    const init = async () => {
        const response = await get('get-country')
        if (response && response.data.code) {
            const country = response.data.code
            // TODO: tmp
            if (country !== 'lt') {
                showModal(Delivery)
            }
        }
    }

    useEffect(() => {
        if (!cookieDelivery) {
            init()
        }
    }, [])

    const handleClick = () => {
        showModal(Delivery)
    }

    return (
        <div className={styles.button} onClick={handleClick}>
            <div>
                <div>Pasirinktas siuntimas:</div>
                <b>{names[delivery]}</b>
            </div>
            <Svg name={`delivery-${delivery}`} />
        </div>
    )
}
