import { useState, useMemo, useRef, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
// import algoliasearch from 'algoliasearch/lite'
import { createAutocomplete } from '@algolia/autocomplete-core'
// import { getAlgoliaResults } from '@algolia/autocomplete-preset-algolia'
import useOnClickOutside from 'utils/hooks/useOnClickOutside'
import Product from './product'
import Account from './account'
import Icon from 'components/icon/icon'
import { cn, getRoute } from 'utils/helpers'
import styles from './autocomplete.module.scss'
import inputStyles from 'components/input/wrap.module.scss'
import useSWR from 'swr'
import useDebounce from 'utils/hooks/useDebounce'
import { getUrl } from 'utils/api'
import Typesense from 'typesense'

// import searchClient from 'utils/lp'
// const searchClient = algoliasearch('17NKOJ20GN', 'b9f911fb218131049eb286fa2446a630')

let typesenseClient = new Typesense.Client({
    'nearestNode': {'host': 'jm465nzda83iykoep-1.a1.typesense.net', 'port': '443', 'protocol': 'https'},
    'nodes': [
        {'host': 'jm465nzda83iykoep-1.a1.typesense.net', 'port': '443', 'protocol': 'https'},
    ],
    'apiKey': 'FDQQtQTJebodt88s1Zi9zhb4RQEunzft',
    'connectionTimeoutSeconds': 2
})

function debouncePromise(fn, time) {
    let timerId = undefined

    return function debounced(...args) {
        if (timerId) {
            clearTimeout(timerId)
        }

        return new Promise(resolve => {
            timerId = setTimeout(() => resolve(fn(...args)), time)
        })
    }
}

const debounced = debouncePromise(items => Promise.resolve(items), 400)

export default function Autocomplete() {
    const [inputFocus, setInputFocus] = useState(false)
    const [query, setQuery] = useState('')
    const debouncedQuery = useDebounce(query, 400)
    const { data } = useSWR(debouncedQuery && getUrl('accounts/search', { query: debouncedQuery }))
    const { data: data2 } = useSWR(debouncedQuery && getUrl('query-suggestions', { query: debouncedQuery }))
    const router = useRouter()
    const [autocompleteState, setAutocompleteState] = useState({
        collections: []
    })
    const autocomplete = useMemo(
        () =>
            createAutocomplete({
                onStateChange({ state }) {
                    setQuery(state.query)
                    setAutocompleteState(state)
                },
                onChange() {
                    console.log(this.onChange)
                    //
                },
                async getSources({ query }) {
                    console.log({iiiiiiiiiiiiiiiii:query});
                    const results = await typesenseClient.collections('products').documents().search({
                        q: query,
                        query_by: 'name',
                        highlight_full_fields: 'name',
                        include_fields: 'id,name,url,image,slug,brand_name,account_slug,brand_slug'
                    })

                    return debounced([
                        {
                            sourceId: 'products',
                            getItemInputValue({ item }) {
                                return item && item.document ? item.document.name : '';
                                // return item.query
                            },
                            getItems() {
                                return results.hits
                                // return getAlgoliaResults({
                                //     searchClient,
                                //     queries: [
                                //         {
                                //             indexName: 'products',
                                //             query,
                                //             params: {
                                //                 filters: 'is_visible:true',
                                //                 hitsPerPage: 4
                                //             }
                                //         }
                                //     ]
                                // })
                            },
                            getItemUrl({ item }) {
                                return item && item.document ? item.document.url : ''
                                // return item.document.url
                            }
                        },
                        // {
                        //     sourceId: 'suggestions',
                        //     getItemInputValue({ item }) {
                        //         return item.query
                        //     },
                        //     getItems({ query }) {
                        //         return getAlgoliaResults({
                        //             searchClient,
                        //             queries: [
                        //                 {
                        //                     indexName: 'products_query_suggestions',
                        //                     query,
                        //                     params: {
                        //                         hitsPerPage: 4,
                        //                         highlightPreTag: '<u>',
                        //                         highlightPostTag: '</u>'
                        //                     }
                        //                 }
                        //             ]
                        //         })
                        //     },
                        //     getItemUrl({ item }) {
                        //         return item.url
                        //     }
                        // }
                    ])
                }
            }),
        []
    )

    const inputRef = useRef(null)
    const formRef = useRef(null)
    const panelRef = useRef(null)

    const { getEnvironmentProps } = autocomplete

    useEffect(() => {
        if (!(formRef.current && panelRef.current && inputRef.current)) {
            return
        }

        const { onTouchStart, onTouchMove } = getEnvironmentProps({
            formElement: formRef.current,
            panelElement: panelRef.current,
            inputElement: inputRef.current
        })

        window.addEventListener('touchstart', onTouchStart)
        window.addEventListener('touchmove', onTouchMove)

        return () => {
            window.removeEventListener('touchstart', onTouchStart)
            window.removeEventListener('touchmove', onTouchMove)
        }
    }, [getEnvironmentProps, formRef, panelRef, inputRef])

    // const suggestions = {items:['a']}

    // const [products, suggestions] = autocompleteState.collections
    const [products] = autocompleteState.collections

    const handleClose = () => {
        autocomplete.setQuery('')
        autocomplete.setIsOpen(false)
        document.activeElement.blur()
    }

    const handleClick = (product, withoutPush = false) => {
        handleClose()
        if (!withoutPush) {
            router.push(`/preke/${product.slug}`)
        }
    }

    const handleSearchClick = e => {
        e.preventDefault()
        e.stopPropagation()
        // console.log(query, autocompleteState.query)
        router.push(`/paieska?query=${query || ''}`)
        handleClose()
    }

    const handleSearchClear = () => {
        inputRef.current.value = ''
        autocomplete.getInputProps().onChange({ currentTarget: { value: '' } })
        inputRef.current.focus()
    }

    useOnClickOutside(panelRef, handleClose)

    // useEffect(() => {
    //     const open = (suggestions && autocompleteState.isOpen) || inputFocus
    //     if (open) {
    //         document.querySelector('html').classList.add('noScroll')
    //     } else {
    //         document.querySelector('html').classList.remove('noScroll')
    //     }
    // }, [autocompleteState.isOpen, suggestions, inputFocus])

    useEffect(() => {
        const open = (autocompleteState.isOpen) || inputFocus
        if (open) {
            document.querySelector('html').classList.add('noScroll')
        } else {
            document.querySelector('html').classList.remove('noScroll')
        }
    }, [autocompleteState.isOpen, inputFocus])

    const searchUrl = getRoute('search')

    const handleKeyPress = e => {
        if (e.which === 13) {
            handleSearchClick(e)
        }
    }

    // const handleChange = e => {
    //     setQuery(e.target.value)
    //     console.log(autocomplete.getInputProps({}))
    //     autocomplete.getInputProps({}).onChange(e)
    // }
    // onChange={handleChange}

    return (
        <form
            className={styles.container}
            ref={formRef}
            {...autocomplete.getFormProps({
                inputElement: inputRef.current
            })}
            onMouseDown={e => {
                if (e.target.tagName === 'BUTTON') {
                    e.preventDefault()
                    e.stopPropagation()
                }
            }}
        >
            <div className={cn(inputStyles.input, inputStyles.simple, styles.input)}>
                <input
                    ref={inputRef}
                    {...autocomplete.getInputProps({})}
                    onFocus={() => setInputFocus(true)}
                    onBlur={() => setInputFocus(false)}
                    onKeyPress={handleKeyPress}
                    placeholder="Paieška"
                    aria-labelledby="0"
                    id="0"
                />
            </div>
            {autocompleteState.query && (
                <Icon
                    icon="x"
                    label="Ištrinti paieškos tekstą"
                    btn
                    className={cn(styles.icon, styles.xIcon)}
                    onClick={handleSearchClear}
                />
            )}
            <Icon icon="search" label="Paieška" btn className={styles.icon} onClick={handleSearchClick} />
            {autocompleteState.isOpen && (
                <div ref={panelRef} {...autocomplete.getPanelProps({})}>
                    <div className={styles.content}>
                        <div className={styles.left}>
                            {data2?.length > 0 && (
                                <ul className={cn(styles.list, styles.suggestions)} {...autocomplete.getListProps()}>
                                    <li>
                                        <Link href={`/paieska?query=${query || ''}`}>
                                            <a onClick={handleClose} className={styles.all}>
                                                Visi paieškos rezultatai
                                            </a>
                                        </Link>
                                    </li>
                                    {data2.map(item => (
                                        <li key={item.query} className={styles.suggestion}>
                                            <Link href={`${searchUrl}?query=${item}`}>
                                                <a
                                                    onClick={handleClose}
                                                    style={{display: 'block'}}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item //item._highlightResult.query.value
                                                    }}
                                                />
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </div>
                        <div className={styles.right}>
                            {products.items.length > 0 && (
                                <>
                                    <div className={styles.label}>Prekės</div>
                                    <ul className={styles.list} {...autocomplete.getListProps()}>
                                        {products.items.map(item => (
                                            <Product
                                                key={item.document.id}
                                                item={item.document}
                                                onClick={handleClick}
                                                props={autocomplete.getItemProps({
                                                    item: item.document,
                                                    source: 'products'
                                                })}
                                            />
                                        ))}
                                    </ul>
                                </>
                            )}
                            {!!data && !!data.length && (
                                <>
                                    <div className={styles.label}>Parduotuvės</div>
                                    <ul className={styles.list}>
                                        {data.map(item => (
                                            <Account handleClick={() => handleClose()} key={item.slug} item={item} />
                                        ))}
                                    </ul>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </form>
    )
}
