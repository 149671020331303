import { useEffect, useState } from 'react'
import styles from './promo.module.scss'
import useAppState from 'utils/state'
import { cn } from 'utils/helpers'
import Link from 'next/link'
import useRemoveCoupon from 'utils/hooks/useRemoveCoupon'

const getTimeRemaining = endtime => {
    const total = Date.parse(endtime) - Date.parse(new Date()) - 1000
    const seconds = Math.floor((total / 1000) % 60)
    const minutes = Math.floor((total / 1000 / 60) % 60)
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24)
    const days = Math.floor(total / (1000 * 60 * 60 * 24))

    return {
        total,
        days,
        hours,
        minutes,
        seconds
    }
}

export default function Promo({ coupon, pathname }) {
    const [intervalId, setIntervalId] = useState()
    const [time, setTime] = useState('')
    const { minimizeCoupon } = useAppState()
    const alt = pathname.startsWith('/pirkimas')
    const removeCoupon = useRemoveCoupon()
    //JUODASIS50
    useEffect(() => {
        setIntervalId(setInterval(setTimeLeft, 1000))

        setTimeLeft()

        return () => {
            if (intervalId) {
                clearInterval(intervalId)
            }
        }
    }, [])

    const setTimeLeft = () => {
        const { days, hours, minutes, seconds, total } = getTimeRemaining(coupon.end)
        if (total < 0) {
            setTime(null)
        } else {
            const left =
                days > 200
                    ? `${days} d. `
                    : `${hours}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

            setTime(left)
        }
    }

    if (!time) return null

    const black = coupon.code.toLowerCase().includes('juodasis') && styles.black

    return (
        <div
            onClick={() => (coupon.minimized ? minimizeCoupon(false) : null)}
            className={cn(styles.promo, coupon.minimized && styles.minimized, alt && styles.alt, black)}
        >
            <span className={styles.row}>
                Pritaikytas kodas {coupon.code}
                <i onClick={removeCoupon}>✕</i>
            </span>
            <span className={styles.row}>Nuolaidos iki {coupon.max}%</span>
            <span className={styles.row}>
                Likęs laikas <b>{time}</b>
            </span>
            {!alt && (
                <Link href="/parduotuviu-nuolaidos">
                    <a className={styles.link}>Peržiūrėti dalyvaujančias parduotuves</a>
                </Link>
            )}
            <span onClick={() => minimizeCoupon(true)} className={styles.mini} />
        </div>
    )
}
