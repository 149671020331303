import styles from './price.module.scss'
import useAppState from 'utils/state'
import { cn } from 'utils/helpers'

export default function Price({ product, itemProp, withOld, asPoints, className }) {
    const { couponCheck } = useAppState()
    const { price_preview, old_price_preview } = couponCheck(product)
    return (
        <span className={cn(styles.price, className)}>
            {asPoints ? (
                <b itemProp={itemProp} content={priceContent(product.price_preview)}>
                    {Math.round(product.single_price * 200)} taškų
                </b>
            ) : (
                <>
                    <b itemProp={itemProp} content={priceContent(price_preview)}>
                        {price_preview}
                    </b>
                    {withOld && old_price_preview && <span className={styles.oldPrice}>{old_price_preview}</span>}
                </>
            )}
        </span>
    )
}

const priceContent = string => {
    return string ? string.split(' - ')[0].replace('€', '') : ''
}
