import useCheckout from 'utils/checkout'
import Cookies from 'universal-cookie'
import useAppState from 'utils/state'

export default function useRemoveCoupon() {
    const { setCoupon } = useAppState()
    const { updateSetup } = useCheckout()
    const cookies = new Cookies()

    const removeCoupon = () => {
        if (confirm('Ar tikrai norite panaikinti nuolaidų kodą?')) {
            setCoupon(null)
            cookies.set('coupon', '', { path: '/', expires: new Date(Date.now() + 86400000) })
            updateSetup(null, 'n')
        }
    }

    return removeCoupon
}
