import { useState } from 'react'
import Link from 'next/link'
import { cn } from 'utils/helpers'
import styles from './submenu.module.scss'
import stylesTax from './taxonomies.module.scss'

export default function Sublist({
    index,
    group,
    active,
    activeMobile,
    activeMobileList,
    hideMenu,
    setActiveMobile,
    setActiveMobileList,
    alt
}) {
    const [activeList, setActiveList] = useState(0)

    return (
        <>
            <Link href={group.url}>
                <a
                    onClick={hideMenu}
                    className={styles.groupName}
                    dangerouslySetInnerHTML={{
                        __html: group.name
                    }}
                />
            </Link>
            <u className={styles.next} onClick={() => setActiveMobile(index)}></u>
            <div
                className={cn(
                    styles.taxonomyList,
                    active && styles.activeGroup,
                    activeMobile && styles.activeGroupMobile,
                    alt && styles.alt
                )}
            >
                <div className={styles.taxonomyMainlist}>
                    {group.children.map((taxonomy, b) => (
                        <div key={b} className={styles.relative}>
                            <Link href={taxonomy.url}>
                                <a onClick={hideMenu} onMouseEnter={() => setActiveList(b)}>
                                    <span className={activeList === b ? styles.activeLink : null}>{taxonomy.name}</span>
                                    {/*<span className={styles.count}>{taxonomy.count}</span>*/}
                                </a>
                            </Link>
                            <u className={styles.next} onClick={() => setActiveMobileList(b)}></u>
                        </div>
                    ))}
                    <div className={cn(stylesTax.listName, 'desktopHide')}>
                        <span
                            dangerouslySetInnerHTML={{
                                __html: group.name
                            }}
                        />
                        <u onClick={() => setActiveMobile(null)} className={styles.back}></u>
                    </div>
                </div>
                {group.children.length &&
                    group.children[0].children &&
                    group.children.map(
                        (taxonomy, b) =>
                            !!taxonomy.children.length && (
                                <div
                                    key={b}
                                    className={cn(
                                        styles.taxonomySublist,
                                        b == activeList && styles.activeList,
                                        b == activeMobileList && styles.activeMobileList
                                    )}
                                >
                                    {taxonomy.children.map((subtaxonomy, c) => (
                                        <Link key={c} href={subtaxonomy.url}>
                                            <a onClick={hideMenu}>
                                                {subtaxonomy.name}
                                                {/*<span className={styles.count}>{subtaxonomy.count}</span>*/}
                                            </a>
                                        </Link>
                                    ))}
                                    <div className={cn(stylesTax.listName, 'desktopHide')}>{taxonomy.name}</div>
                                    <u onClick={() => setActiveMobileList(null)} className={styles.back}></u>
                                </div>
                            )
                    )}
            </div>
        </>
    )
}
