import { createContext, useState, useContext, useEffect } from 'react'
import { post } from 'utils/api'
import useAppState from 'utils/state'
import { useRouter } from 'next/router'

const CheckoutContext = createContext({
    setup: {},
    updateSetup: () => {},
    startPayment: () => {},
    setupLoading: true
})

export function CheckoutContextProvider({ children }) {
    const { loading, couponLoading, cart, coupon } = useAppState()
    const [setup, setSetup] = useState(true)
    const [setupLoading, setSetupLoading] = useState(true)
    const router = useRouter()

    useEffect(async () => {
        if (!loading && !couponLoading) {
            if (!cart.items.length) {
                router.push('/pirkimas/krepselis')
            } else {
                await updateSetup(cart.details.delivery.delivery)
                setSetupLoading(false)
            }
        }
    }, [loading, couponLoading])

    async function startPayment(details) {
        // details
        const { items, basket_id } = cart
        const request = prepareData({ items, details }) // { items, details }
        request.coupon = coupon ? coupon.code : ''
        const { data } = await post('start-payment', { ...request, basket_id })
        return data
    }

    async function updateSetup(deliveryType, couponCode) {
        const request = prepareData(cart) /// cart
        if (deliveryType) {
            request.delivery.delivery = deliveryType
        }
        request.coupon = couponCode ? couponCode : coupon ? coupon.code : ''
        const { data } = await post('checkout-prices', request)
        setSetup(data)
    }

    function prepareData({ items, details }) {
        const { information, delivery, payment } = details
        const filteredItems = getItems(items)
        const formatedItems = filteredItems.map(({ data, id }) => ({ ...data, id }))
        return {
            information,
            delivery,
            payment,
            items: formatedItems
        }
    }

    function getItems() {
        return cart.items.filter(item => item.account_slug === cart.details.account_slug)
    }

    const context = {
        setup,
        updateSetup,
        setupLoading,
        startPayment
    }

    return <CheckoutContext.Provider value={context}>{children}</CheckoutContext.Provider>
}

export default function useCheckout() {
    return useContext(CheckoutContext)
}
