import Link from 'next/link'
import Image from 'components/image/image'
import styles from './product.module.scss'
import { getRoute } from 'utils/helpers'

export default function Account({ item, handleClick }) {
    return (
        <div className={styles.product}>
            <div className={styles.image}>
                <div className={styles.innerImage}>
                    <Image src={item.image} alt={item.name} layout="responsive" width={72} height={72} />
                </div>
            </div>
            <div className={styles.right}>
                <div className={styles.text}>
                    <h3>{item.name}</h3>
                    <div className={styles.brand}>{item.short_description}</div>
                </div>
            </div>
            <Link href={getRoute('shop', item)}>
                <a className={styles.fullLink} onClick={handleClick} aria-label={item.name}></a>
            </Link>
        </div>
    )
}
